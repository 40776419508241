<template>
  <div class="flex flex-col justify-center items-center">
    <SignupHeader
      :step="$t('global_step_02_03')"
      :title="$t('magical_link_verification')"
      back="primaryregistration"
    />
    <div class="w-9/12 mt-16">
      <img
        class="h-28 mb-12 mx-auto md:mx-0"
        rel="prefetch"
        src="@/assets/images/laptop-icon-cl.png"
        alt="laptop-icon-cl"
      />
    </div>
    <div class="w-10/12 lg:w-9/12 text-left font-proximaNormal">
      <h2 class="text-2xl">{{ $t("magical_link_verification_title") }}</h2>
      <h5 class="text-sm my-4">
        {{ $t("magical_link_verification_info_text") }}
      </h5>
      <FormError :errors="errorEmail" />
      <FormError :errors="errorPhnoneNumber" />
      <!--  <FormInfo :infos="infOtpComp" /> -->
      <!--   <SubmitButton
        :title="$t('magical_link_verification_continue_button')"
        background="bg-primary"
        @click.native="openEmailClient"
      /> -->
      <!-- @click.native="sendMagicLinkByEmailAndOtp" -->
    </div>
  </div>
</template>

<script>
import SignupHeader from "../primaryregistration/SignupHeader.vue";
import SocialLoginForm from "../socialloginverif/SocialLoginForm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import FormError from "../warning/FormError.vue";
import FormInfo from "../warning/FormInfo.vue";

export default {
  props: {
    email_address: String,
    phone_number: String,
    servicePracticeType: Number,
  },

  components: {
    SignupHeader,
    SocialLoginForm,
    SubmitButton,
    FormError,
    FormInfo,
  },
  data() {
    return {
      email: "",
      errorEmail: [],
      errorPhnoneNumber: [],
    };
  },
  async mounted() {
    /*  if (!this.email_address || !this.phone_number ) {
      this.errorEmail.push(
        "Your session has expired. Please go back to register again."
      );
      this.$router.push({ name: "PrimaryRegistration" });
    } */
  },
  computed: {
    infOtpComp() {
      console.log("*********************");
      return this.$store.state.infOtp;
    },
  },
  methods: {
    /*  async openEmailClient() {
      window.location.href = "mailto:" + this.email_address;
      
    }, */
  },
};
</script>

<style></style>
