<template>
  <p class="" v-if="infos">
    <span
      v-for="(error, i) in infos"
      :key="i"
      class="text-xs text-green font-proximaLight"
      >{{ error }}</span
    >
  </p>
</template>

<script>
export default {
  props: ["infos"],
};
</script>
